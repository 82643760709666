define("discourse/plugins/discourse-ford-plugin/discourse/connectors/admin-user-controls-after/profile-sync-button", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/helper", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _helper, _object, _service, _dButton, _ajax, _ajaxError, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class _default extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "loading", [_tracking.tracked]))();
    #loading = (() => (dt7948.i(this, "loading"), void 0))();
    fetchUserProfile(username) {
      this.loading = true;
      (0, _ajax.ajax)(`/profile_sync/update/${username}`, {
        method: "POST"
      }).then(() => {
        this.dialog.alert((0, _discourseI18n.i18n)("admin.profile_sync_button.message"));
      }).catch(_ajaxError.popupAjaxError).finally(() => this.loading = false);
    }
    static #_3 = (() => dt7948.n(this.prototype, "fetchUserProfile", [_object.action]))();
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DButton
          @action={{fn this.fetchUserProfile @outletArgs.model.username}}
          @icon="retweet"
          @label="admin.profile_sync_button.title"
          @isLoading={{this.loading}}
        />
      
    */
    {
      "id": "UQ7H7CXH",
      "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@action\",\"@icon\",\"@label\",\"@isLoading\"],[[28,[32,1],[[30,0,[\"fetchUserProfile\"]],[30,1,[\"model\",\"username\"]]],null],\"retweet\",\"admin.profile_sync_button.title\",[30,0,[\"loading\"]]]],null],[1,\"\\n  \"]],[\"@outletArgs\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ford-plugin/discourse/connectors/admin-user-controls-after/profile-sync-button.js",
      "scope": () => [_dButton.default, _helper.fn],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = _default;
});