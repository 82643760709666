define("discourse/plugins/discourse-ford-plugin/discourse/components/ford-profile-field", ["exports", "@ember/component", "@ember/template-factory"], function (_exports, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if (get this.profile this.key)}}
    <div class="field">
      <span class="field_name">
        {{#if this.icon}}{{d-icon this.icon}}{{/if}}
        {{i18n (concat "ford.profile_field." this.key)}}:
      </span>
      <span class="field_value">{{get this.profile this.key}}</span>
    </div>
  {{/if}}
  */
  {
    "id": "bPEMzI1I",
    "block": "[[[41,[28,[37,1],[[30,0,[\"profile\"]],[30,0,[\"key\"]]],null],[[[1,\"  \"],[10,0],[14,0,\"field\"],[12],[1,\"\\n    \"],[10,1],[14,0,\"field_name\"],[12],[1,\"\\n      \"],[41,[30,0,[\"icon\"]],[[[1,[28,[35,4],[[30,0,[\"icon\"]]],null]]],[]],null],[1,\"\\n      \"],[1,[28,[35,5],[[28,[37,6],[\"ford.profile_field.\",[30,0,[\"key\"]]],null]],null]],[1,\":\\n    \"],[13],[1,\"\\n    \"],[10,1],[14,0,\"field_value\"],[12],[1,[28,[35,1],[[30,0,[\"profile\"]],[30,0,[\"key\"]]],null]],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"get\",\"div\",\"span\",\"d-icon\",\"i18n\",\"concat\"]]",
    "moduleName": "discourse/plugins/discourse-ford-plugin/discourse/components/ford-profile-field.hbs",
    "isStrictMode": false
  });
  class FordProfileField extends _component.default {}
  _exports.default = FordProfileField;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FordProfileField);
});